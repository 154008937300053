<template>
  <v-card class="page-container balance-details">
    <v-row>
      <v-col cols="12">
        <div class="member-balance">
          <app-member-balance :parent="this.routeName.MOBILE_ACCOUNT"></app-member-balance>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="MB-row mx-4 pa-2 viptier-details" :class="`level${currentLv}`" v-if="memberVipTierProgress">
      <v-col>
        <v-row no-gutters>
          <v-col cols="3" class="MB-left-col">
            <v-row class="justify-center align-center">
              <v-avatar tile size="70" class="pr-3">
                <v-img v-if="memberVipTierProgress && memberVipTierProgress.image" :src="`${memberVipTierProgress.image}`"></v-img>
                <v-img v-else :src="`/static/image/viptier/${tierColorThemeName}.png`" eager></v-img>
              </v-avatar>
            </v-row>
          </v-col>
          <v-col cols="9" class="MB-right-col d-flex">
            <v-row no-gutters>
              <span style="text-transform: uppercase;text-align: center">{{ tierName }}</span>
            </v-row>
            <v-row no-gutters>
              <v-progress-linear :value="currentProgressPercentage" size="20" :background-color="`viptier${currentLv}`" color="black"></v-progress-linear>
            </v-row>
            <v-row no-gutters class="progress-details-col">
              <span>{{ $t(`label.lv`) }} {{ currentLv }}</span>
              <span>{{ progressLabel }}</span>
              <!--              <div v-if="currentLv < 7">-->
              <!--                <span v-if="currentProgressPercentage === 100">-->
              <!--                  {{ $t(`label.vipTierPendingUpgrade`) }}-->
              <!--                </span>-->
              <!--                <span v-else>{{ currentDeposit }} / {{ requiredDeposit }}</span>-->
              <!--              </div>-->
              <!--              <div v-else>-->
              <!--                &lt;!&ndash;                highest tier level &ndash;&gt;-->
              <!--                <span v-if="currentProgressPercentage === 100">-->
              <!--                  {{ $t(`label.vipTierRetentionFulFilled`) }}-->
              <!--                </span>-->
              <!--                <span v-else>{{ currentDeposit }} / {{ requiredDeposit }}</span>-->
              <!--              </div>-->
              <span v-if="currentLv < 6">{{ $t(`label.lv`) }} {{ nextLv }}</span>
              <span v-else>{{ $t(`label.lv`) }} {{ currentLv }}</span>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="MB-btm-row px-3" no-gutters @click="goToTierDetails">
          <v-col cols="10">
            <span style="font-size: 10px; color: black">
              {{ $t(`label.viewMemberShip`) }}
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-icon color="black">mdi-chevron-right</v-icon>
        </v-row>
      </v-col>
    </v-row>

    <!--    <v-list>-->
    <v-list-item-group v-model="model" multiple v-if="this.currentCurrency === 'BDT'">
      <!--parent nav items        -->
      <v-list-item v-for="(item, i) in navItemsBD" :key="i">
        <v-list-group class="parentNav-list-group" :class="navItems.length > i + 1 ? '' : 'no-border'">
          <v-icon width="24" height="24" slot="prependIcon" color="primary" class="parentNav-icon">{{ `$${item.icon}` }}</v-icon>
          <template v-slot:activator>
            <v-list-item-content class="parentNav-item-content">
              <v-list-item-title class="parentNav-title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- children nav items          -->
          <div v-for="child in item.items" :key="child.title">
            <!--external routes/links-->
            <v-list-item v-if="child.isExtLink" class="child-list-item" :href="child.route" target="_blank">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
            <!--application routes-->
            <v-list-item v-else class="child-list-item" :to="{ name: child.route, params: { lang: $route.params.lang, tab: child.tab } }">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group v-model="model" multiple v-else>
      <!--parent nav items        -->
      <v-list-item v-for="(item, i) in navItems" :key="i">
        <v-list-group class="parentNav-list-group" :class="navItems.length > i + 1 ? '' : 'no-border'">
          <v-icon width="24" height="24" slot="prependIcon" color="primary" class="parentNav-icon">{{ `$${item.icon}` }}</v-icon>
          <template v-slot:activator>
            <v-list-item-content class="parentNav-item-content">
              <v-list-item-title class="parentNav-title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- children nav items          -->
          <div v-for="child in item.items" :key="child.title">
            <!--external routes/links-->
            <v-list-item v-if="child.isExtLink" class="child-list-item" :href="child.route" target="_blank">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
            <!--application routes-->
            <v-list-item v-else class="child-list-item" :to="{ name: child.route, params: { lang: $route.params.lang } }">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list-item>
    </v-list-item-group>
    <!--      <v-list-item-group  v-model="item.active" :prepend-icon="item.action" no-action >-->

    <!--        &lt;!&ndash;expansion list&ndash;&gt;-->
    <!--        <v-list-item v-for="child in item.items" :key="child.title" :to="{ name: child.route }">-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ child.title }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--      </v-list-item-group>-->

    <!--      <v-list-group v-for="(item, index) in specialNavItems" :key="item.title" v-model="item.active" :prepend-icon="item.icon">-->
    <!--        <template v-slot:activator>-->
    <!--          <v-list-item-title v-text="item.title"></v-list-item-title>-->
    <!--        </template>-->
    <!--        <v-list-item v-for="child in item.items" :key="child.title" :to="{name:child.route}">-->
    <!--          <v-list-item-title>{{ child.title }}</v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list-group>-->
    <!--    </v-list>-->
  </v-card>
</template>

<script>
import { uiHelper, locale } from '@/util'
import AppMemberBalance from '@/components/member/MemberBalance.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
import { MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'
export default {
  name: 'mobileAccount',
  components: {
    AppMemberBalance
  },
  data: () => ({
    progressLabel: '',
    tierBarBgColor: '',
    tierBarColor: '',
    tierColorThemeName: 'newmember',
    tierName: '',
    currentProgressPercentage: 0,
    currentLv: 0,
    nextLv: 0,
    progressRequired: 0,
    currentDeposit: 0,
    requiredDeposit: 0,
    routeName: ROUTE_NAME,
    currentCurrency: uiHelper.getCurrency(),
    currency: SHARED.DEFAULT_CURRENCY,
    specialNavItems: [
      {
        icon: 'mdi-calendar-star',
        title: 'Special 2',
        items: [
          {
            title: 'Refer & Earn',
            route: ROUTE_NAME.REFERRAL_TIER
          }
        ]
      }
    ],
    model: [0, 3],
    navItems: [
      {
        title: locale.getMessage('label.history'),
        active: true,
        icon: 'customHistory',
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.betHistory'),
            route: ROUTE_NAME.MOBILE_BETTING_HISTORY
          },
          {
            title: locale.getMessage('label.turnoverHistory'),
            route: ROUTE_NAME.MOBILE_TO_HISTORY
          },
          {
            title: locale.getMessage('mobileMenuTitle.walletHistory'),
            route: ROUTE_NAME.MOBILE_TRANSACTION_HISTORY
          }
          // {
          //   title: 'Promotion History',
          //   route: ROUTE_NAME.MOBILE_PROMO_HISTORY
          // }
          // {
          //   title: 'Transactions',
          //   route: ROUTE_NAME.MOBILE_ACCOUNT
          // }
          // {
          //   title:'',
          //   route:ROUTE_NAME
          // }
        ]
      },
      {
        icon: 'customSpecial',
        title: locale.getMessage('mobileMenuTitle.special'),
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.referral'),
            route: ROUTE_NAME.REFERRAL_TIER
          },
          {
            title: locale.getMessage('mobileMenuTitle.bettingPass'),
            route: ROUTE_NAME.VIP
          },
          {
            title: locale.getMessage('mobileMenuTitle.agentAffiliate'),
            route: uiHelper.getaffLink(),
            isExtLink: true
          }
        ]
      },
      {
        icon: 'customRewards',
        title: locale.getMessage('mobileMenuTitle.rewards'),
        items: [
          {
            title: locale.getMessage('label.claimVoucher'),
            route: ROUTE_NAME.CLAIM_VOUCHER
          },
          {
            title: locale.getMessage('label.wheelSpin'),
            route: ROUTE_NAME.MWOF
          },
          {
            title: locale.getMessage('label.dailyCheckIn'),
            route: ROUTE_NAME.REWARDS
          }
          // {
          //   title: locale.getMessage('mobileMenuTitle.rewardStore'),
          //   route: ROUTE_NAME.REWARD_STORE_ITEM
          // }
          // {
          //   title: locale.getMessage('label.coinGrab'),
          //   route: ROUTE_NAME.GRAB_COIN
          // }
        ]
      },
      {
        icon: 'customSocial',
        title: locale.getMessage('mobileMenuTitle.social'),
        items: [
          // {
          //   title: locale.getMessage('label.liveChat').toUpperCase(),
          //   isExtLink: true,
          //   route: uiHelper.getLiveChatUrl()
          // },
          {
            title: 'Facebook',
            isExtLink: true,
            route: uiHelper.getFBLink()
          },
          {
            title: 'Instagram',
            isExtLink: true,
            route: uiHelper.getInstaLink()
          },
          {
            title: 'Telegram',
            isExtLink: true,
            route: uiHelper.getTlgLink()
          },
          {
            title: 'Twitter',
            isExtLink: true,
            route: uiHelper.getTwtLink()
          },
          {
            title: 'YouTube',
            isExtLink: true,
            route: uiHelper.getYTLink()
          }
        ]
      },
      {
        icon: 'customSetting',
        title: locale.getMessage('mobileMenuTitle.setting'),
        items: [
          { title: locale.getMessage('label.bankDetail'), route: ROUTE_NAME.BANK_ACCOUNT },
          {
            title: locale.getMessage('label.profile'),
            route: ROUTE_NAME.PERSONAL
          },
          { title: locale.getMessage('label.changePassword'), route: ROUTE_NAME.CHANGE_PASSWORD }
        ]
      }
    ],
    navItemsBD: [
      {
        title: locale.getMessage('label.history'),
        active: true,
        icon: 'customHistory',
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.betHistory'),
            route: ROUTE_NAME.MOBILE_BETTING_HISTORY
          },
          {
            title: locale.getMessage('label.turnoverHistory'),
            route: ROUTE_NAME.MOBILE_TO_HISTORY
          },
          {
            title: locale.getMessage('mobileMenuTitle.walletHistory'),
            route: ROUTE_NAME.MOBILE_TRANSACTION_HISTORY
          }
          // {
          //   title: 'Promotion History',
          //   route: ROUTE_NAME.MOBILE_PROMO_HISTORY
          // }
          // {
          //   title: 'Transactions',
          //   route: ROUTE_NAME.MOBILE_ACCOUNT
          // }
          // {
          //   title:'',
          //   route:ROUTE_NAME
          // }
        ]
      },
      {
        icon: 'customSpecial',
        title: locale.getMessage('mobileMenuTitle.special'),
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.referral'),
            route: ROUTE_NAME.REFERRAL_TIER
          },
          {
            title: locale.getMessage('mobileMenuTitle.bettingPass'),
            route: ROUTE_NAME.VIP
          },
          {
            title: locale.getMessage('mobileMenuTitle.agentAffiliate'),
            route: uiHelper.getaffLink(),
            isExtLink: true
          }
        ]
      },
      {
        icon: 'customRewards',
        title: locale.getMessage('mobileMenuTitle.rewards'),
        items: [
          {
            title: locale.getMessage('label.claimVoucher'),
            route: ROUTE_NAME.CLAIM_VOUCHER
          },
          {
            title: locale.getMessage('label.wheelSpin'),
            route: ROUTE_NAME.MWOF
          },
          {
            title: locale.getMessage('label.dailyCheckIn'),
            route: ROUTE_NAME.REWARD_STORE_ITEM,
            tab: 'dailyCheckIn'
          },
          {
            title: locale.getMessage('mobileMenuTitle.rewardStore'),
            route: ROUTE_NAME.REWARD_STORE_ITEM
          }
          // {
          //   title: locale.getMessage('label.coinGrab'),
          //   route: ROUTE_NAME.GRAB_COIN
          // }
        ]
      },
      {
        icon: 'customSocial',
        title: locale.getMessage('mobileMenuTitle.social'),
        items: [
          // {
          //   title: locale.getMessage('label.liveChat').toUpperCase(),
          //   isExtLink: true,
          //   route: uiHelper.getLiveChatUrl()
          // },
          {
            title: 'Facebook',
            isExtLink: true,
            route: uiHelper.getFBLink()
          },
          {
            title: 'Instagram',
            isExtLink: true,
            route: uiHelper.getInstaLink()
          },
          {
            title: 'Telegram',
            isExtLink: true,
            route: uiHelper.getTlgLink()
          },
          {
            title: 'Twitter',
            isExtLink: true,
            route: uiHelper.getTwtLink()
          },
          {
            title: 'YouTube',
            isExtLink: true,
            route: uiHelper.getYTLink()
          }
        ]
      },
      {
        icon: 'customSetting',
        title: locale.getMessage('mobileMenuTitle.setting'),
        items: [
          { title: locale.getMessage('label.bankDetail'), route: ROUTE_NAME.BANK_ACCOUNT },
          {
            title: locale.getMessage('label.profile'),
            route: ROUTE_NAME.PERSONAL
          },
          { title: locale.getMessage('label.changePassword'), route: ROUTE_NAME.CHANGE_PASSWORD }
        ]
      }
    ]
  }),
  mounted() {
    this.metaTag()
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing
    },
    vipTierUpgrade() {
      return this.$store.state.member.vipTierProgress.upgrade
    },
    vipTierDowngrade() {
      return this.$store.state.member.vipTierProgress.downgrade
    }
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getVipTierProgress()
      }
    },
    vipTierUpgrade() {
      // if (Object.keys(this.vipTierUpgrade).length > 0) {
      //   if (this.vipTierUpgrade.current_upgrade_deposit > 0) {
      //     this.currentProgressPercentage = Math.ceil((this.vipTierUpgrade.current_upgrade_deposit / this.vipTierUpgrade.required_upgrade_deposit) * 100)
      //   } else {
      //     this.currentProgressPercentage = 0
      //   }
      //
      //   this.progressRequired = this.vipTierUpgrade.required_upgrade_deposit - this.vipTierUpgrade.current_upgrade_deposit
      // }
    },
    memberVipTierProgress() {
      let vipTierProgress = this.$store.state.member.vipTierProgress
      if (Object.keys(vipTierProgress.pairing).length > 0) {
        let curPairing = vipTierProgress.pairing
        //progress found
        this.showProgress = true
        switch (curPairing.level_sequence) {
          case 0:
            this.tierColorThemeName = 'newmember'
            break
          case 1:
            this.tierColorThemeName = 'bronze'
            break
          case 2:
            this.tierColorThemeName = 'silver'
            break
          case 3:
            this.tierColorThemeName = 'gold'
            break
          case 4:
            this.tierColorThemeName = 'emerald'
            break
          case 5:
            break
          case 6:
            this.tierColorThemeName = 'platinum'
            break
          case 7:
            this.tierColorThemeName = 'diamond'
            break
          default:
            this.tierColorThemeName = 'newmember'
            this.tierBarBgColor = '#1867c0'
        }
        this.tierBarBgColor = 'viptier' + this.tierColorThemeName
        this.tierName = curPairing.name
        this.currentLv = this.memberVipTierProgress.level_sequence
        this.nextLv = this.memberVipTierProgress.level_sequence + 1

        this.setVipTierProgressDetails()
      } else {
        //no progress
        this.showProgress = false
      }
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.getVipTierProgress()
    }
  },
  methods: {
    setVipTierProgressDetails() {
      if (this.currentLv < 6) {
        if (this.vipTierUpgrade) {
          if (this.vipTierUpgrade.required_upgrade_deposit && this.vipTierUpgrade.required_upgrade_deposit >= 0) {
            this.requiredDeposit = Number(this.vipTierUpgrade.required_upgrade_deposit)
          }

          if (this.vipTierUpgrade.current_upgrade_deposit && this.vipTierUpgrade.current_upgrade_deposit >= 0) {
            this.currentDeposit = Number(this.vipTierUpgrade.current_upgrade_deposit)
          }

          this.progressLabel = this.currentDeposit.toString() + '/' + this.requiredDeposit.toString()
        } else {
          if (this.vipTierUpgrade == null) {
            //pending upgrade tier
            this.progressLabel = locale.getMessage('label.vipTierPendingUpgrade')
          }
        }
      } else {
        //highest tier level in automation = 6 , level 7 is manual upgrade rquired
        if (this.vipTierDowngrade) {
          if (this.vipTierDowngrade.required_downgrade_deposit && this.vipTierDowngrade.required_downgrade_deposit >= 0) {
            this.requiredDeposit = Number(this.vipTierDowngrade.required_downgrade_deposit)
          }

          if (this.vipTierDowngrade.current_downgrade_deposit && this.vipTierDowngrade.current_downgrade_deposit >= 0) {
            this.currentDeposit = Number(this.vipTierDowngrade.current_downgrade_deposit)
          }

          this.progressLabel = this.currentDeposit.toString() + '/' + this.requiredDeposit.toString()
        } else {
          //check for whether retention fulfilled
          if (this.vipTierDowngrade == null && this.vipTierUpgrade == null) {
            this.progressLabel = locale.getMessage(`label.vipTierRetentionFulFilled`)
          }
        }
      }

      if (this.requiredDeposit > 0) {
        this.currentProgressPercentage = Math.ceil((this.currentDeposit / this.requiredDeposit) * 100)
      } else {
        //assume Denominator = 0 mean no need to upgrade / fulfilled
        //either retention fulfilled or pending upgrade
        this.currentProgressPercentage = 100
      }

      //regarding upgrade/downgrade is null or not as long we 100% then display fulfillment message
      if (this.currentProgressPercentage === 100) {
        if (this.currentLv < 6) {
          this.progressLabel = locale.getMessage('label.vipTierPendingUpgrade')
        } else {
          this.progressLabel = locale.getMessage('label.vipTierRetentionFulFilled')
        }
      }
    },
    goToTierDetails() {
      this.$router.push({ name: this.routeName.VIPTIER, params: { lang: this.$route.params.lang } })
    },
    getVipTierProgress() {
      this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
    },
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    }
  }
}
</script>

<style lang="scss">
.v-progress-linear__background {
  background-color: #fff !important;
  opacity: 1 !important;
}
.progress-details-col {
  display: flex;
  justify-content: space-between;
  font-size: 0.78rem;
}
.viptier-details {
  border-radius: 10px;
}
.balance-details {
  height: 100%;
  font-style: normal;
  font-weight: 600;
  .MB-row {
    align-items: center;

    .MB-left-col {
      display: flex;
      flex-direction: column;
      gap: 8px 0px;

      .highlighted-span {
        font-size: 0.9rem;
      }
    }

    .MB-right-col {
      flex-direction: column;
      gap: 8px 0px;
    }
  }
  /* newmember */
  .level0 {
    //background: linear-gradient(98deg, #d0b6fe 0%, #cc9dda 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #5d0076 !important;
    background-color: #fddd04;
    color: black;
  }
  /* //bronze */
  .level1 {
    //background: linear-gradient(98deg, #f4cfb9 0%, #e1af92 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #915e51 !important;
    background-color: #fddd04;
    color: black;
  }
  /* //silver */
  .level2 {
    //background: linear-gradient(98deg, #c2c0da 0%, #9899c1 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #5a557f !important;
    background-color: #fddd04;
    color: black;
  }
  /* //ruby */
  .level3 {
    //background: linear-gradient(98deg, #f4c1c1 0%, #f97c7c 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #e10531 !important;
    background-color: #fddd04;
    color: black;
  }
  /* //gold */
  .level4 {
    //background: linear-gradient(98deg, #f1dc9c 0%, #ebc573 100%, #ebc573 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #915e51 !important;
    background-color: #fddd04;
    color: black;
  }
  /* //platinum */
  .level5 {
    //background: linear-gradient(98deg, #cedfff 0%, #89a8e4 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #6717cd !important;
    background-color: #fddd04;
    color: black;
  }

  .level6 {
    //background: linear-gradient(98deg, #cedfff 0%, #89a8e4 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #6717cd !important;
    background-color: #fddd04;
    color: black;
  }

  .level7 {
    //background: linear-gradient(98deg, #cedfff 0%, #89a8e4 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    //color: #6717cd !important;
    background-color: #fddd04;
    color: black;
  }

  &.bronze {
    background: linear-gradient(98deg, #f4cfb9 0%, #e1af92 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    color: #915e51 !important;
  }

  &.silver {
    background: linear-gradient(98deg, #b5b3cf 0%, #7377b5 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    color: #6164a7 !important;
  }
  &.gold {
    background: linear-gradient(98deg, #f1dc9c 0%, #ebc573 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    color: #915e51 !important;
  }

  .MB-btm-row {
    align-items: center;
    font-size: 0.78rem;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    border-top: 0.5px solid #ffffff;
    margin-top: 15px;
  }
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before,
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}

.page-container {
  height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-list-item:hover::before {
  opacity: 0 !important;
}

.parentNav-list-group {
  width: 100%;
  border-bottom: 2px solid #dadada;

  .v-list-group__header {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &.v-list-group--active {
    border-bottom: unset;
  }
  //parentNav expanded or active
  .v-list-group__header .v-item--active {
    border-bottom: 2px solid #dadada;
  }
  .parentNav-icon {
  }
  .v-list-group__header__prepend-icon {
    margin-right: 15px !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  //parentNav group appendicon
  //.v-list-group--active>.v-list-group__header>.v-list-group__header__append-icon .v-icon{
  //  transform();
  //}
}
.no-border {
  border-bottom: unset;
}
.parentNav-title {
  font-size: 0.9rem !important;
}
.child-list-item {
  padding: 0 0 0 10px;
}
.childNav-list-group {
  width: 100%;
  .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: unset !important;
  }

  .childNav-title {
    color: #9b9b9b !important;
    font-size: 0.88rem !important;
  }

  .v-item--active {
    border-bottom: unset !important;
  }
}

.mdi-chevron-right {
  transform: unset !important;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 25px;
}
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 25px;
}
.member-balance {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 35px;
  margin-bottom: 20px;
}
</style>
